import * as React from "react";
import { Link } from "../atoms/Link";

export const Banner = ({ title, accentTitle, text, buttonText, url }) => {
  return (
    <div className="bg-action-100">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-24 lg:px-8">
        <div className="mx-auto max-w-xl text-center lg:text-left lg:max-w-none lg:flex lg:items-center lg:justify-between">
          <div className="text-gray-500 mr-8">
            <h2 className="text-xl font-extrabold tracking-tight text-gray-900 md:text-2xl">
              <span className="block">{title}</span>
              <span className="block text-action-600">{accentTitle}</span>
            </h2>
            {text && <span className="block text-lg mt-2">{text}</span>}
          </div>
          {url && (
            <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0 justify-center lg:justify-start">
              <div className="inline-flex rounded-md shadow">
                <Link
                  to={url}
                  className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-action-600 hover:bg-action-700"
                >
                  {buttonText}
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
